import homeLogo from "../../assets/pics/header/header-logo-home.svg";
import logo from "../../assets/pics/header/header-logo.svg";
import mobileNavbarIcon from "../../assets/icons/mobile-navbar-icon.svg";
import { useLocation } from "react-router-dom";
import classes from "./navbar.module.css";
import { useState } from "react";
import { MobileNavbar } from "./mobile-navbar/mobileNavbar";

export const Navbar: React.FC = () => {
  const path: string = useLocation().pathname.toLowerCase();
  const [isExpand, setIsExpand] = useState<boolean>(false);

  return (
    <nav className={classes.desktopNavContainer}>
      <a href="/">
        <img
          className="w-[215px] min-w-[215px]"
          src={path === "/" ? homeLogo : logo}
        />
        {/* Co-packing */}
      </a>
      <div className={classes.desktopNav}>
        <div>
          <a href="/" className={path === "/" ? classes.selected : ""}>
            Startseite
          </a>
        </div>
        <div>
          <a
            href="careers"
            className={
              path === "/careers" || path === "/careers/job-title"
                ? classes.selected
                : ""
            }
          >
            Karriere
          </a>
        </div>
        <div>
          <a
            href="/contact-us"
            className={path === "/contact-us" ? classes.selected : ""}
          >
            Kontakt
          </a>
        </div>
        <div>
          <a
            href="/about-us"
            className={path === "/about-us" ? classes.selected : ""}
          >
            Über uns
          </a>
        </div>
      </div>
      <button
        className={classes.navbarBtn}
        onClick={() => setIsExpand((s) => !s)}
      >
        <img src={mobileNavbarIcon} />
      </button>
      {isExpand && (
        <div
          className="w-full h-full z-20 fixed inset-0"
          onClick={() => setIsExpand((s) => !s)}
        ></div>
      )}
      <MobileNavbar isExpand={isExpand} />
    </nav>
  );
};
