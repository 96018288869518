import { useState } from "react";
import axios from "axios";
import api from "../../constants/API";
import { ContactUsForm } from "../../types/forms";
const useContactUs = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  const contactUs = async (
    { company_name, description, mobile, email, name, position }: ContactUsForm,
    callback?: any
  ) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("company_name", company_name);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("position", position);
    formData.append("description", description);
    try {
      setIsLoading(true);
      await axios
        .post(api.user.contact_us, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async ({ data }) => {
          setIsLoading(false);
          setResult(data);
          callback(data);
          return data;
        });
    } catch (e: any) {
      setIsLoading(false);
      console.log(e);
      setError(e);
      return e;
    }
  };

  return {
    contactUs,
    result,
    isLoading,
    error,
  };
};
export default useContactUs;
