import { NavLink } from "react-router-dom";
import classes from "./outlinedBtn.module.css";

interface OutlinedBtnWrapperProps {
  children: React.ReactNode;
  linkedTo?: string;
  prevent?: boolean;
  full?: boolean;
  onClick?: () => void;
}

export const OutlinedBtnWrapper: React.FC<OutlinedBtnWrapperProps> = ({
  children,
  linkedTo,
  prevent = false,
  full = false,
  onClick,
}) => {
  return prevent ? (
    <button className={`${classes.outlinedBtn} ${full ? classes.full : "" }`} onClick={onClick}>
      {children}
    </button>
  ) : (
    <a href={`${linkedTo}`} className={`${classes.outlinedBtn} ${full ? classes.full : "" }`}>
      {children}
    </a>
  );
};