import TabsList from "../../components/Tabs/TabsList";
import { useEffect, useState } from "react";
import useCheckToken from "../../hooks/useCheckToken";
import AllGoldenSportsRequestsTable from "./co-packing/allRequestsTable";
const Home = () => {
  const checkToken = useCheckToken();
  useEffect(() => {
    checkToken();
  }, []);
  return (
    <>
      {/* <TabsList
        tabsContainerSx={{
          backgroundColor: "#f9f9f9",
        }}
        tabIndicatorSx={{
          backgroundColor: "black",
        }}
        tabSx={{
          color: "black",
          "&.Mui-selected": {
            color: "black",
            fontWeight: "700",
          },
        }}
        tabs={[
          {
            children: <AllGoldenSportsRequestsTable />,
            title: "Golden Sports",
          },
          {
            children: <AllSommerCupRequestsTable />,
            title: "Sommer Cup",
          },
        ]}
        initialValue={0}
      ></TabsList> */}
      <AllGoldenSportsRequestsTable />{" "}
    </>
  );
};
export default Home;
