import phoneIcon from "../../assets/pics/footer/phone.svg";
import emailIcon from "../../assets/pics/footer/email.svg";
import locationIcon from "../../assets/pics/footer/location.svg";
import logo from "../../assets/pics/header/header-logo.svg";
import classes from "./footer.module.css";

export const Footer: React.FC = () => {
  return (
    <footer>
      <div className={classes.logoContainer}>
        {/* <span> */}
          <img src={logo} />
        {/* </span> */}
      </div>
      <div className={classes.middleColumn}>
        <div>
          <a href="/">Startseite</a>
          <a href="/careers">Karriere</a>
          <a href="/contact-us">Kontakt</a>
          <a href="/about-us">Über uns</a>
        </div>
        <div className={classes.divider}></div>
        <div>
          <a href="/about-us">Impressum</a>
          <span>Datenschutz</span>
          <a href="/privacy-policy">AGB</a>
        </div>
      </div>
      <div className={classes.contact}>
        <span>Kontakt</span>
        <div>
          <img src={phoneIcon} />
          <span>0660/1630972 - 0664/2048911</span>
        </div>
        <div>
          <img src={emailIcon} />
          <span>office@copackingsolutions.com</span>
        </div>
        <div>
          <img src={locationIcon} />
          <span>
            CoPackingSolutions Collaboration GmbH <br />
            Am Schubertbrunnen 21<br />
            2112 Würnitz
          </span>
        </div>
      </div>
    </footer>
  );
};