import JoinUsHeader from "../../components/careers/header/header";
import { Navbar } from "../../components/navbar/navbar";
import classes from "./careers.module.css";
import SectionJobTitle from "../../components/careers/sections-job-title/sectionJobTitle";
import MonthlyGross from "../../components/careers/monthly-gross/monthlyGross";
import ApplicationSectionCreers from "../../components/careers/application-section/applicationSectionCareers";
import SectionApplication from "../../components/careers/section-application/sectionApplication";

interface ListSectionsType {
  id: number;
  text: string;
}

interface SectionsDataType {
  id: number;
  title: string;
  items: ListSectionsType[]
}

const initialSectionsData:SectionsDataType[] = [
  {
    id: 1,
    title: "Deine Aufgaben",
    items: [
      {
        id: 1,
        text: "Du bist in verschiedenen Bereichen  des Lagers tätig",
      },
      {
        id: 2,
        text: "Die Fertigware ordnungsgemäß verpacken, schlichten und etikettieren",
      },
      {
        id: 3,
        text: "Die Einhaltung von zeitlichen qualitativen Vorgaben",
      },
      {
        id: 4,
        text: "Die Sicherstellung der Sauberkeit im Lager",
      },
    ],
  },
  {
    id: 2,
    title: "Deine Profil",
    items: [
      {
        id: 1,
        text: "Du bist zuverlässig, flexibel und verantwortungsbewusst",
      },
      {
        id: 2,
        text: "Du hast eine schnelle Auffassungsgabe",
      },
      {
        id: 3,
        text: "Du besitzt grundlegende Deutschkenntnisse",
      },
      {
        id: 4,
        text: "Du hast Freude an der Mitarbeit in einem Team",
      },
    ],
  },
  {
    id: 3,
    title: "Das bieten wir",
    items: [
      {
        id: 1,
        text: "Einen zukunftssicheren Arbeitsplatz  mit professioneller Einarbeitung.",
      },
      {
        id: 2,
        text: "Verlässliche und pünktliche Entlohnung.",
      },
      {
        id: 3,
        text: "Interessante und abwechslungsreiche  Tätigkeiten.",
      },
      {
        id: 4,
        text: "Wertschätzendes und kollegiales Miteinander.",
      },
      {
        id: 5,
        text: "Ein ausgezeichnetes Betriebsklima mit  Du-Kultur.",
      },
    ],
  },
];

const Careers = () => {
  return (
    <div className={classes.join_us_wrapper}>
      <Navbar />
      <JoinUsHeader />
      {/* <WorkPlace /> */}
      {/* <JobOpenning /> */}
      {/* <LastSection /> */}
      {
        initialSectionsData.map(item => (
            <SectionJobTitle key={item.id} title={item.title} items={item.items} />
        ))
      }
      <MonthlyGross />
      <ApplicationSectionCreers />
      <SectionApplication />
    </div>
  );
};

export default Careers;