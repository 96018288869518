import classes from "./header.module.css";
import HeaderImage from "./../../../assets/pics/about-us/header-image.png";
import HeaderItemImage1 from "./../../../assets/pics/about-us/header-item1.svg";
import HeaderItemImage2 from "./../../../assets/pics/about-us/header-item2.svg";
import HeaderItemImage3 from "./../../../assets/pics/about-us/header-item3.svg";
import HeaderItemImage4 from "./../../../assets/pics/about-us/header-item4.svg";
import HeaderItem from "./header-item/headerItem";

interface ItemsType {
    id: number;
    image: string;
    title: string;
    explain: string;
  }
  
  const initialHeaderItmes: ItemsType[] = [
    {
      id: 1,
      title: "Zuverlässigkeit",
      explain: "Lorem ipsum",
      image: HeaderItemImage1,
    },
    {
      id: 2,
      title: "Kundenzufriedenheit",
      explain: "Lorem ipsum",
      image: HeaderItemImage2,
    },
    {
      id: 3,
      title: "Produktivität",
      explain: "Lorem ipsum",
      image: HeaderItemImage3,
    },
    {
      id: 4,
      title: "Qualität",
      explain: "Lorem ipsum",
      image: HeaderItemImage4,
    },
  ];

const Header = () => {
  return (
    <div className={classes.about_us_header_wrapepr}>
      <div className={classes.about_us_header}>
        <div className={classes.image}>
          <img src={HeaderImage} />
        </div>
        <div className={classes.title}>
          <h1>Unser Team!</h1>
        </div>
        <div className={classes.items}>
          {initialHeaderItmes.map((item) => (
            <HeaderItem key={item.id} info={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Header;
