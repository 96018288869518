import style from "./contactUs.module.css";
import pic1 from "../../assets/pics/contact-us/pic1.svg";
import call from "../../assets/icons/call.svg";
import sms from "../../assets/icons/sms.svg";
import location from "../../assets/icons/location.svg";
import { Navbar } from "../../components/navbar/navbar";
import { useForm } from "react-hook-form";
import { ContactUsForm } from "../../types/forms";
import useContactUs from "../../hooks/api/useContactUs";
import SuccessModal from "../../components/modal/successModal/SuccessModal";
import { useState } from "react";
import ErrorModal from "../../components/modal/errorModal/ErrorModal";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { ContainedBtnWrapper } from "../../ui/contained-btn/containedBtn";

export const ContactUs: React.FC = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<ContactUsForm>();

  const email_regular =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const { contactUs, isLoading, result } = useContactUs();

  const onSubmit = (form: ContactUsForm) => {
    contactUs(form, (r: any) => {
      if (r?.result) {
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
    });
  };

  return (
    <>
      <SuccessModal
        isOpen={showSuccess}
        massage="Ihre Massage wurde gesendet."
        onClick={() => {
          setShowSuccess(false);
          navigate("/");
        }}
        title="Danke für die Nachricht"
      />
      <ErrorModal
        isOpen={showError}
        massage={result?.data?.message}
        onClick={() => setShowError(false)}
        title="Fehler"
      />
      <div className={style.contactUs}>
        <Navbar />
        <div className={style.head}>
          <img src={pic1} alt="" />
          <h2>
            <span>Wir freuen uns auf Ihre Kontaktaufnahme!</span>
          </h2>
        </div>
        <div className={style.botContainer}>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className={style.formContainer}
          >
            <div className={style.input}>
              <label>Vor- und Nachname</label>
              <div className={style.inputContainer}>
                <input
                  {...register("name", {
                    required: "Bitte füllen Sie dieses Feld aus.",
                  })}
                  type="text"
                  placeholder="Text"
                />
              </div>
              <h6 className={style.errorText}>{errors.name?.message}</h6>
            </div>
            <div className={style.input}>
              <label>E-Mail</label>
              <div className={style.inputContainer}>
                <input
                  {...register("email", {
                    required: "Bitte füllen Sie dieses Feld aus.",
                    pattern: {
                      value: email_regular,
                      message: "Diese E-Mail ist nicht korrekt.",
                    },
                  })}
                  type="text"
                  placeholder="Text"
                />
              </div>
              <h6 className={style.errorText}>{errors.email?.message}</h6>
            </div>
            <div className={style.input}>
              <label>Telefonnummer</label>
              <div className={style.inputContainer}>
                <input
                  {...register("mobile", {
                    required: "Bitte füllen Sie dieses Feld aus.",
                  })}
                  type="text"
                  placeholder="Text"
                />
              </div>
              <h6 className={style.errorText}>{errors.mobile?.message}</h6>
            </div>
            <div className={style.input}>
              <label>Name der Firma</label>
              <div className={style.inputContainer}>
                <input
                  {...register("company_name", {
                    required: "Bitte füllen Sie dieses Feld aus.",
                  })}
                  type="text"
                  placeholder="Text"
                />
              </div>
              <h6 className={style.errorText}>
                {errors.company_name?.message}
              </h6>
            </div>
            <div className={style.input}>
              <label>Position in der Firma</label>
              <div className={style.inputContainer}>
                <input
                  {...register("position", {
                    required: "Bitte füllen Sie dieses Feld aus.",
                  })}
                  type="text"
                  placeholder="Text"
                />
              </div>
              <h6 className={style.errorText}>{errors.position?.message}</h6>
            </div>
            <div className={style.input}>
              <label>Nachricht</label>
              <div className={style.inputContainer}>
                <textarea
                  {...register("description", {
                    required: "Bitte füllen Sie dieses Feld aus.",
                  })}
                  placeholder="Text"
                />
              </div>
              <h6 className={style.errorText}>{errors.description?.message}</h6>
            </div>
            <div className={style.action}>
              <div className={style.checkbox_layout}>
                <input
                  type="checkbox"
                  {...register("checkBox", {
                    required: "Bitte akzeptieren Sie unsere Rollen.",
                  })}
                />
                <div className={style.checkbox_wrapper}>
                  <div className={style.checkbox}></div>
                  <div className={style.text}>
                    <p>
                      Ich akzeptiere die{" "}
                      <NavLink to="/privacy-policy">
                        Datenschutzbestimmungen
                      </NavLink>
                      .
                    </p>
                  </div>
                </div>
                {errors.checkBox && (
                  <span className={style.errorText}>
                    {errors.checkBox.message}
                  </span>
                )}
              </div>
              <ContainedBtnWrapper disabled={isLoading} prevent>
                {isLoading ? "Warten Sie mal ..." : "Versenden"}
              </ContainedBtnWrapper>
            </div>
          </form>
          <div className={style.talkToUs}>
            <h2>Rede mit uns</h2>
          </div>
          <div className={style.detailContainer}>
            <div className={style.kontaktContainer}>
              <h3>Kontakt</h3>
              <div>
                <img src={call} alt="call" />
                <span>0660/1630972</span>
              </div>
              <span className={style.divider}></span>
              <div>
                <img src={sms} alt="sms" />
                <span>office@copackingsolutions.com</span>
              </div>
              <span className={style.divider}></span>
              <div>
                <img src={location} alt="location" />
                <span>
                  CoPackingSolutions Collaboration GmbH <br />
                  Am Schubertbrunnen 21
                  <br />
                  2112 Würnitz
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
