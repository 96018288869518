import classes from "./sectionThree.module.css";

const SectionThree = () => {
  return (
    <div className={classes.section_three_wrapper}>
      <div className={classes.section_three}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>Was zeichnet uns aus?</h1>
          </div>
          <div className={classes.explain}>
            <p>
              Unser Unternehmen ist für uns mehr als nur Business. Jedes
              Mitglied unseres Teams hat seine Stärken, die er engagiert für
              Ihren Erfolg am POS einbringt.
            </p>
            <p>
              Wir sind stolz darauf mit unserer Kundenorientierung und
              Flexibilität an Ihrer Erfolgsgeschichte mitzuschreiben.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionThree;
