import classes from "./applicationSectionCareers.module.css";

import CallIcon from "./../../../assets/icons/call.svg";
import SmsIcon from "./../../../assets/icons/sms.svg";

const ApplicationSectionCreers = () => {
  return (
    <div className={classes.application_section_careers_wrapper}>
      <div className={classes.application_section_careers}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>Anwendung</h1>
          </div>
          <div className={classes.personal}>
            <div className={classes.name}>
              <p>Herr Yilmaz</p>
            </div>
            <div className={classes.information}>
              <div className={classes.item}>
                <div className={classes.image}>
                  <img src={CallIcon} />
                </div>
                <div className={classes.text}>
                  <p>+43 660/1094900</p>
                </div>
              </div>
              <div className={classes.item}>
                <div className={classes.image}>
                  <img src={SmsIcon} />
                </div>
                <div className={classes.text}>
                  <p>office@copackingsolutions.com</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.explain_title}>
            <p>Wir freuen uns auf Dich!</p>
          </div>
          <div className={classes.explain}>
            <p>
              *Im Sinne der Gleichbehandlung – unberücksichtigt von Herkunft,
              Alter, Religion, Geschlecht, Behinderung, sexueller Orientierung
              oder Identität – wenden wir uns gleichermaßen an alle Personen!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationSectionCreers;
