import classes from "./sectionOne.module.css";

import WhoWeAreImage1 from "./../../../assets/pics/about-us/who-we-are-image1.png";
import WhoWeAreImage2 from "./../../../assets/pics/about-us/who-we-are-image2.png";
import WhoWeAreImage3 from "./../../../assets/pics/about-us/who-we-are-image3.png";
import WhoWeAreImage4 from "./../../../assets/pics/about-us/who-we-are-image4.png";
import WhoWeAreItem from "./../who-we-are/whoWeAreItem";

interface PeopleType {
  id: number;
  image: string;
  name: string;
  position: string;
  email: string;
}

const initialHowWeAre: PeopleType[] = [
  {
    id: 1,
    image: WhoWeAreImage1,
    name: "Ali Yilmaz",
    position: "Operative Geschäftsführung",
    email: "ali.yilmaz@copackingsolutions.com",
  },
  {
    id: 2,
    image: WhoWeAreImage2,
    name: "Christoph Niemann",
    position: "Kaufmännische Geschäftsführung",
    email: "christoph.niemann@copackingsolutions.com",
  },
  {
    id: 3,
    image: WhoWeAreImage3,
    name: "Sabine Marx",
    position: "Kaufmännische Leitung",
    email: "controlling@copackingsolutions.com",
  },
  {
    id: 4,
    image: WhoWeAreImage4,
    name: "Nico Niemann",
    position: "Founder Associate",
    email: "office@copackingsolutions.com",
  },
];

const SectionOne = () => {
  return (
    <div className={classes.section_one_wrapper}>
      <div className={classes.section_one}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>Wer sind wir?</h1>
          </div>
          <div className={classes.explain}>
            <p>
              Mit über 30 Jahren Branchenerfahrung im Co-Packing sind wir ein
              leistungsstarker und kontinuierlicher Partner in Sachen
              Produktivität und Zuverlässigkeit. Wir sehen uns als innovatives
              und aufstrebendes Unternehmen, mit dem obersten Ziel - der
              Kundenzufriedenheit. Bei uns sprechen Sie direkt mit den
              Entscheidungsträgern oder nahen Personen zu diesen, welche sich
              mit einem respektvollen Umgang mit jedermann stets darum bemühen
              Ihren Wünschen zu entsprechen.
            </p>
            <p>
              Um das zu erreichen, setzen wir dieses Bestreben mit höchstem Maß
              an Qualität, Vertrauen und Transparenz um.
            </p>
          </div>
          <div className={classes.items}>
            {initialHowWeAre.map((item) => (
              <WhoWeAreItem key={item.id} info={item} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionOne;
