import classes from "./aboutUs.module.css";

import Header from "../../components/about-us/header/header";
import { Navbar } from "../../components/navbar/navbar";
import SectionOne from "../../components/about-us/section-one/sectionOne";
import SectionTwo from "../../components/about-us/section-two/sectionTwo";
import Impressum from "../../components/about-us/impressum/impressum";
import SectionThree from "../../components/about-us/section-three/sectionThree";

export const AboutUs: React.FC = () => {
  return (
    <>
      <Navbar />
      <div className={classes.about_us_wrapper}>
        <div className={classes.about_us}>
          <Header />
          <SectionOne />
          <SectionThree />
          <SectionTwo />
          <Impressum />
        </div>
      </div>
    </>
  );
};
