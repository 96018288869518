import { useState } from "react";
// import gif1 from "../../../assets/pics/home/what-section/gif1.gif";
// import gif2 from "../../../assets/pics/home/what-section/gif2.gif";
// import gif3 from "../../../assets/pics/home/what-section/gif3.gif";
// import gif4 from "../../../assets/pics/home/what-section/gif4.gif";
import gif1 from "../../../assets/pics/home/Unsere Kompetenzen-1.gif";
import gif2 from "../../../assets/pics/home/Unsere Kompetenzen-2.gif";
import gif3 from "../../../assets/pics/home/Unsere Kompetenzen-3.gif";
import gif4 from "../../../assets/pics/home/Unsere Kompetenzen-4.gif";
import UnsereKompetenzenGif1 from "./../../../assets/pics/home/Unsere Kompetenzen-1.gif";
import UnsereKompetenzenGif2 from "./../../../assets/pics/home/Unsere Kompetenzen-2.gif";
import UnsereKompetenzenGif3 from "./../../../assets/pics/home/Unsere Kompetenzen-3.gif";
import UnsereKompetenzenGif4 from "./../../../assets/pics/home/Unsere Kompetenzen-4.gif";
import { PackItemType } from "../../../types/types";
import { Modal } from "./modal/modal";
import { PackItem } from "./packItem/packItem";
import classes from "./whatSection.module.css";

const packList: PackItemType[] = [
  {
    id: 1,
    title: `Ihre Displays sind bei uns in besten <br />Händen`,
    pic: gif1,
    text: `Wir sind Ihr zuverlässiger Partner für kreative und individuelle <br />Displaylösungen!`,
    paragragh: `Als kompetenter CoPacker konfektionieren wir Ihre Displays nach Ihren Vorstellungen.<br />Dabei arbeiten wir eng mit unseren Kunden zusammen, um ihre individuellen Anforderungen und Bedürfnisse zu verstehen und entsprechend umzusetzen. Um dies zu garantieren, erstellen wir einen Produktpass, mit der genauen Aufbauanleitung und Bestückung. Erst nach Ihrer Freigabe beginnen wir mit der Produktion. <br />Maßgeschneiderte Lösungen, die Ihre Produkte optimal in Szene setzen, die nicht nur funktional, sondern auch ästhetisch ansprechend sind. Damit ist Ihre nächste erfolgreiche Präsentation am POS garantiert. <br />Displays bestücken wir nach Ihren Vorstellungen, fehlerfrei und zeitgerecht. Nach dem Konfektionieren kümmern wir uns mit Hilfe von Überkartons, Bundles, Kantenschutz und der richtigen Folierung auch noch um die Transportsicherung, um jegliche Transportschäden zu vermeiden. <br />Kontaktieren Sie uns noch heute, um mehr über unsere Displaylösungen zu erfahren und gemeinsam mit uns Ihre nächste erfolgreiche Präsentation zu planen.`,
    modalPic: UnsereKompetenzenGif1,
  },
  {
    id: 2,
    title: "Individuell zugeschnittene <br />Containerlösungen",
    pic: gif2,
    text: `Ihr Experte für Be- und Entladung von Containern!`,
    paragragh: `Jede gut funktionierende Logistik benötigt auch eine organisierte Be- und Entladung in kürzester Zeit. Diese stellen wir für Sie mit unseren Fachkräften rasch und flexibel sicher. <br />Wir ermöglichen jedem unserer Kunden eine ressourcen- und zeitsparende Alternative. Dies bieten wir in unserem eigenen Lager, aber auch direkt auf Ihrer Fläche an. <br />Ob große oder kleine Mengen, wir verladen Kartons und Kisten aus den Containern und sortieren diese auf die von Ihnen gewünschten Ladungsträgern. <br />Wir stellen sicher, dass Ihre Container ordnungsgemäß geladen und gesichert sind, um das Risiko von Schäden während des Transports zu minimieren. <br />Unser Team verfügt über das nötige Know-how und die Fähigkeiten, selbst schwere und sperrige Güter zu handhaben und sicherzustellen, dass sie sicher und unbeschädigt an ihren Bestimmungsort gelangen.`,
    modalPic: UnsereKompetenzenGif2,
  },
  {
    id: 3,
    title: "Zusatzleistungen",
    pic: gif3,
    text: `Wir bieten eine breite Palette von Zusatzleistungen, wie z.B. Etikettierung, Leergutsortierung und div. Kontrollarbeiten`,
    paragragh: `<strong>Etikettierungen:</strong> Mit unseren Etikettendruckern können wir Etiketten mit Barcodes mit SSCC, GTIN, Chargen und MHD, etc. bedrucken und diese anschließend auf den Produkten platzieren. <br />Wir arbeiten mit Geräten modernster Technologie, um eine genaue und zuverlässige Etikettierung sicherzustellen, die den höchsten Qualitätsstandards entspricht. <br />Wir erarbeiten mit Ihnen die beste Etikettierungsstrategie, denn ohne fehlerfreies und effizientes Etikettieren würden die meisten Lieferketten wie wir sie kennen, nicht funktionieren. <br /><strong>Leergutsortierung:</strong> Als erfahrener Partner für die Getränkeindustrie bieten wir Ihnen eine breite Palette an Sortierungslösungen für Ihre spezifischen Anforderungen. Unsere Mitarbeiter sortieren eine Vielzahl an Getränkeverpackungen wie Flaschen, Dosen, Kisten, Fässer und vieles mehr. <br /><strong>Kontrollarbeiten:</strong> Unsere erfahrenen und qualifizierten Mitarbeiter führen eine gründliche Überprüfung Ihrer Produkte durch, um sicherzustellen, dass alle erforderlichen Standards erfüllt sind. Dazu gehören eine sorgfältige Überprüfung der Verpackung, des Etiketts, des Inhalts und vieles mehr.`,
    modalPic: UnsereKompetenzenGif3,
  },
  {
    id: 4,
    title: "Maschinelle Verpackungslösungen",
    pic: gif4,
    text: `Maßgeschneiderte Lösungen für eine Vielzahl von Branchen, wie z.B. <br />Lebensmittel, Getränke, Pharmazie, Kosmetik und vieles mehr.`,
    paragragh: `Wir verstehen, dass eine effektive Verpackungslösung mehr ist als nur eine Maschine. Daher bieten wir unseren Kunden einen umfassenden Service, der von der Beratung über die Planung bis hin zur Durchführung reicht. <br />Unser Service umfasst folgende Leistungen: <br />· Schrumpfen <br />· Sleeven <br />· Einschweißen`,
    modalPic: UnsereKompetenzenGif4,
  },
];

export const WhatSection: React.FC = () => {
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<PackItemType>({
    id: 1,
    title: "",
    pic: "",
    text: "",
    paragragh: "",
    modalPic: "",
  });

  // const openModalFn = () => {
  //   setModalIsOpen(true);
  // };
  // const closeModalFn = () => {
  //   setModalIsOpen(false);
  // };

  const toggleModalFn = () => {
    setModalIsOpen((state) => !state);
  };

  const changeData = (data: PackItemType) => {
    setModalData(data);
  };

  return (
    <>
      <Modal
        modalIsOpen={modalIsOpen}
        toggleModalFn={toggleModalFn}
        modalData={modalData}
      />
      <section className={classes.whatSection}>
        <div id="what-section" className={classes.heading}>
          <h2>Wer sind wir?</h2>
          <p>
            Wir bieten Ihnen mit unserer Erfahrung, die für Sie richtige
            Servicelösung bei Ihnen direkt vor Ort oder bei uns auf der Fläche
            an. Unser geschultes Personal konfektioniert die Aufträge an dem
            gewählten Standort. Selbstverständlich zählen auch Flexibilität und
            Kundenorientierung zu unseren Stärken. Aufgrund unserer hohen
            Produktivität ist es uns möglich, attraktive Angebote für Sie zu
            erstellen.
          </p>
        </div>
        <div className={classes.boxListSection}>
          <h2>Unsere Kompetenzen</h2>
          <p>
            Wir versorgen Sie mit allem, was Sie für die Produktverpackung
            benötigen.
          </p>
          <ul>
            {packList.map((item) => (
              <PackItem
                key={item.id}
                packItem={item}
                toggleModalFn={toggleModalFn}
                changeData={changeData}
              />
            ))}
          </ul>
        </div>
      </section>
    </>
  );
};
