import style from "./whySection.module.css";
import pic1 from "../../../assets/pics/home/why-section/pic1.svg";
import pic2 from "../../../assets/pics/home/why-section/pic2.svg";
import pic3 from "../../../assets/pics/home/why-section/pic3.svg";
import pic4 from "../../../assets/pics/home/why-section/pic4.svg";
import { ContainedBtnWrapper } from "../../../ui/contained-btn/containedBtn";
import { ReasonType } from "../../../types/types";

const reasons: ReasonType[] = [
  {
    id: 1,
    title: "Zuverlässigkeit",
    subtitle: "Lorem ipsum",
    pic: pic1,
  },
  {
    id: 2,
    title: "Produktivität",
    subtitle: "Lorem ipsum",
    pic: pic2,
  },
  {
    id: 3,
    title: "Kundenzufriedenheit",
    subtitle: "Lorem ipsum",
    pic: pic3,
  },
  {
    id: 4,
    title: "Qualität",
    subtitle: "Lorem ipsum",
    pic: pic4,
  },
];

export const WhySection: React.FC = () => {
  return (
    <section className={style.whySection}>
      <h2>Wieso wir?</h2>
      <ul className={style.reasons}>
        {reasons.map((reason) => (
          <li className={style.reason} key={reason.id}>
            <div>
              <img src={reason.pic} alt="" />
              <h3></h3>
              <span>
                <h3>{reason.title}</h3>
                {/* <h4>{reason.subtitle}</h4> */}
              </span>
            </div>
          </li>
        ))}
      </ul>
      <div className={style.contact}>
        <h2>Wir freuen uns auf Ihre Kontaktaufnahme!</h2>
        <p>
          Unsere Experten stehen Ihnen jederzeit für Ihre Fragen zur Verfügung!
        </p>
        <ContainedBtnWrapper linkedTo="/contact-us">
          Kontaktieren Sie uns
        </ContainedBtnWrapper>
      </div>
    </section>
  );
};
