import { useState } from "react";
import { PackItemType } from "../../../../types/types";
import { OutlinedBtnWrapper } from "../../../../ui/outlined-btn/outlinedBtn";
import classes from "./packItem.module.css";

interface PackItemProps {
  packItem: PackItemType;
  toggleModalFn: () => void;
  changeData: (data: PackItemType) => void
}

export const PackItem: React.FC<PackItemProps> = ({
  packItem,
  toggleModalFn,
  changeData
}) => {

  const [loadedImage, setLoadedImage] = useState<boolean>(true);

  const handleModalFn = (packItem: PackItemType) => {
    toggleModalFn()
    changeData(packItem);
  };

  const loadingImage = () => {
    setLoadedImage(false)
  }

  return (
    <li className={classes.packItem}>
      <h4 dangerouslySetInnerHTML={{__html: packItem.title}}></h4>
      {/* <p className={classes.subTitle}>{packItem.subTitle}</p> */}
      <img src={packItem.pic} alt="" onLoad={loadingImage} style={{display: loadedImage ? "none" : "block"}} />
      <p className={classes.txt} dangerouslySetInnerHTML={{__html: packItem.text}}></p>
      <OutlinedBtnWrapper  full prevent onClick={() => handleModalFn(packItem)}>Mehr erfahren</OutlinedBtnWrapper>
    </li>
  );
};