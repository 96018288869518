import classes from "./whoWeAreItem.module.css";

import SmsIcon from "./../../../assets/pics/about-us/icons/sms.svg";

interface InfoType {
  image: string;
  name: string;
  position: string;
  email: string;
}

interface PropsType {
  info: InfoType;
}

const WhoWeAreItem = ({ info }: PropsType) => {
  return (
    <div className={classes.who_we_are}>
      <div className={classes.image}>
        <img src={info.image} />
      </div>
      <div className={classes.caption}>
        <div className={classes.name}>
          <p>{info.name}</p>
        </div>
        <div className={classes.position}>
          <p>{info.position}</p>
        </div>
      </div>
      <div className={classes.send_button}>
        <a href={`mailto: ${info.email}`}>
            <img src={SmsIcon} />
            <div className={classes.email_container}>
              <p>{info.email}</p>
            </div>
        </a>
      </div>
    </div>
  );
};

export default WhoWeAreItem;
