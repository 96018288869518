import "./App.css";
import { useLocation } from "react-router-dom";
import Router from "./router";
import { Footer } from "./components/footer/footer";
import { Navbar } from "./components/navbar/navbar";

function App() {
  const path: string = useLocation().pathname.toLowerCase();
  const hideNavbarIn: string[] = ["/"];
  const hideFooterIn: any[] = [/\/admin\/*/];
  return (
    <div className="App">
      {!hideNavbarIn && <Navbar />}
      <main>
        <Router />
      </main>
      {!hideFooterIn.some((e) => e?.test(path)) ? <Footer /> : null}
    </div>
  );
}

export default App;
