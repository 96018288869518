import { useState } from "react";
import axios from "axios";
import api from "../../constants/API";
import { JobRequestForm } from "../../types/forms";
const useJobRequest = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<any>(null);
  const [result, setResult] = useState<any>(null);
  const sendJobRequest = async (
    { mobile, email, name, job_position }: JobRequestForm,
    callback?: any
  ) => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("job_position", job_position);
    formData.append("mobile", mobile);
    formData.append("email", email);
    try {
      setIsLoading(true);
      await axios
        .post(api.user.job_request, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then(async ({ data }) => {
          setIsLoading(false);
          setResult(data);
          callback(data);
          return data;
        });
    } catch (e: any) {
      setIsLoading(false);
      console.log(e);
      setError(e);
      return e;
    }
  };

  return {
    sendJobRequest,
    result,
    isLoading,
    error,
  };
};
export default useJobRequest;
