import { useLocation } from "react-router-dom";
import classes from "../navbar.module.css";

export const MobileNavbar: React.FC<{ isExpand: boolean }> = ({ isExpand }) => {
  const path: string = useLocation().pathname.toLowerCase();
  return (
    <div
      className={`${classes.popupHeader} ${isExpand ? classes.active : ""}`}
      onClick={(e) => e.stopPropagation()}
    >
      <div className={classes.mobileNavbar}>
        <a href="/" className={path === "/" ? classes.selected : ""}>
          Startseite
        </a>
        <a
          href="careers"
          className={path === "/careers" ? classes.selected : ""}
        >
          Karriere
        </a>
        <a
          href="/contact-us"
          className={path === "/contact-us" ? classes.selected : ""}
        >
          Kontakt
        </a>
        <a
          href="/about-us"
          className={path === "/about-us" ? classes.selected : ""}
        >
          Über uns
        </a>
      </div>
    </div>
  );
};
