import { NavLink } from "react-router-dom";
import classes from "./containedBtn.module.css";

interface ContainedBtnWrapperProps {
  children: React.ReactNode;
  linkedTo?: string;
  prevent?: boolean;
  full?: boolean;
  disabled?: boolean;
}

export const ContainedBtnWrapper: React.FC<ContainedBtnWrapperProps> = ({
  children,
  linkedTo,
  prevent = false,
  full = false,
  disabled = false,
}) => {
  return prevent ? (
    <button type="submit" disabled={disabled} className={`${classes.containedBtn} ${full ? classes.full : "" }`}>
      {children}
    </button>
  ) : (
    <a href={`${linkedTo}`} className={`${classes.containedBtn} ${full ? classes.full : "" }`}>
      {children}
    </a>
  );
};