import classes from "./headerItem.module.css";

interface InfoType {
    image: string;
    title: string;
    explain: string;
}

interface PropsType {
    info: InfoType
}

const HeaderItem = ({info} : PropsType) => {
    return(
        <div className={classes.header_item}>
            <div className={classes.image}>
                <img src={info.image} />
            </div>
            <div className={classes.caption}>
                <div className={classes.title}>
                    <p>{info.title}</p>
                </div>
                {/* <div className={classes.explain}>
                    <p>{info.explain}</p>
                </div> */}
            </div>
        </div>
    )
}

export default HeaderItem;