import medicinePic from "../../../assets/pics/home/medicine-pic.svg";
import cosmeticsPic from "../../../assets/pics/home/cosmetics-pic.svg";
import detergentsPic from "../../../assets/pics/home/detergents-pic.svg";
import foodPic from "../../../assets/pics/home/food-pic.svg";
import textilesPic from "../../../assets/pics/home/textiles-pic.svg";
import petFoodPic from "../../../assets/pics/home/pet-food-pic.svg";
import { IndustryType } from "../../../types/types";
import classes from "./industriesSection.module.css";
import { IndustryItem } from "./industryItem/industryItem";

export const IndustriesSection: React.FC = () => {
  const industries: IndustryType[] = [
    {
      id: 1,
      title: "Medizin",
      pic: medicinePic,
      text: "Lorem ipsum dolor sit amet. Ea optio libero aut rerum nostrum sed.",
    },
    {
      id: 2,
      title: "Kosmetik",
      pic: cosmeticsPic,
      text: "Lorem ipsum dolor sit amet. Ea optio libero aut rerum nostrum sed.",
    },
    {
      id: 3,
      title: "Reinigungsmittel",
      pic: detergentsPic,
      text: "Lorem ipsum dolor sit amet. Ea optio libero aut rerum nostrum sed.",
    },
    {
      id: 4,
      title: "Lebensmittel",
      pic: foodPic,
      text: "Lorem ipsum dolor sit amet. Ea optio libero aut rerum nostrum sed.",
    },
    {
      id: 5,
      title: "Textilien",
      pic: textilesPic,
      text: "Lorem ipsum dolor sit amet. Ea optio libero aut rerum nostrum sed.",
    },
    {
      id: 6,
      title: "Tierfutter",
      pic: petFoodPic,
      text: "Lorem ipsum dolor sit amet. Ea optio libero aut rerum nostrum sed.",
    },
  ];
  return (
    <section className={classes.industriesSection}>
      <div className={classes.heading}>
        <h2>Unsere Dienstleistungen</h2>
        <p>
          Für all diese Produktgruppen und noch mehr, bieten wir Ihnen
          maßgeschneiderte Verpackungslösungen.
        </p>
      </div>
      <ul className={classes.industries}>
        {industries.map((industry) => (
          <IndustryItem key={industry.id} industry={industry} />
        ))}
      </ul>
    </section>
  );
};
