import classes from "./monthlyGross.module.css";

const MonthlyGross = () => {
  return (
    <div className={classes.monthly_gross_wrapper}>
      <div className={classes.monthly_gross}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>ein Monatsbruttolohn von</h1>
          </div>
          <div className={classes.explain}>
            <div className={classes.money}>
              <p>
                <span>€</span>1.816,50
              </p>
            </div>
            <div className={classes.month}>
              <p>(Vollzeit 40 Stunden/Woche)</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlyGross;
