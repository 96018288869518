import { IndustryType } from "../../../../types/types";
import classes from "./industryItem.module.css";

export const IndustryItem: React.FC<{ industry: IndustryType }> = ({
  industry,
}) => {
  return (
    <li className={classes.industryItem}>
      <div className={classes.upperPart}>
        <div className={classes.imgContainer}>
          <img src={industry.pic} alt="" />
        </div>
        <h4>{industry.title}</h4>
      </div>
      {/* <p className={classes.txt}>{industry.text}</p> */}
    </li>
  );
};