import { useCallback, useState } from "react";
import classes from "./greenBoxes.module.css";
import CountUp from "react-countup";

const greenBoxes = [
  { id: 1, number: "2020", text: "Gründung", counter: false },
  { id: 2, number: "97", text: "Mitarbeiter", counter: true },
  { id: 3, number: "5.200 m2", text: "Produktionsfläche", counter: true },
];

export const GreanBoxes: React.FC = () => {

  return (
    <div className={classes.greenBoxes}>
      {greenBoxes.map((box, index) => (
        <div className={classes.greenBox} key={box.id}>
          <span className={classes.number}>
            {index === 2 ? (
              <>
                <CountUp
                  end={+box.number.split(" ")[0].replaceAll(".", "")}
                  duration={5}
                  separator="."
                />
                <span> {box.number.split(" ")[1][0]}</span>
              </>
            ) : index > 0 ? (
              <CountUp end={+box.number} duration={5} />
            ) : (
              box.number
            )}
            {index === 2 && <sup>{box.number.split(" ")[1][1]}</sup>}
          </span>
          <span className={classes.txt}>{box.text}</span>
        </div>
      ))}
    </div>
  );
};
