import { useState } from "react";
import { useForm } from "react-hook-form";
import classes from "./sectionApplication.module.css";
import { JobRequestForm } from "../../../types/forms";
import { useNavigate } from "react-router-dom";
import useJobRequest from "../../../hooks/api/useJobRequest";
import SuccessModal from "../../modal/successModal/SuccessModal";
import ErrorModal from "../../modal/errorModal/ErrorModal";
import { NavLink } from "react-router-dom";
import { ContainedBtnWrapper } from "../../../ui/contained-btn/containedBtn";

const SectionApplication: React.FC = () => {
  const email_regular =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<JobRequestForm>();

  const navigate = useNavigate();

  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const [showError, setShowError] = useState<boolean>(false);

  const { isLoading, result, sendJobRequest } = useJobRequest();

  const submitFunc = (form: JobRequestForm) => {
    sendJobRequest(form, (r: any) => {
      if (r?.result) {
        setShowSuccess(true);
      } else {
        setShowError(true);
      }
    });
  };

  return (
    <>
      <SuccessModal
        isOpen={showSuccess}
        massage="Die Anwendung ist abgeschlossen."
        onClick={() => {
          setShowSuccess(false);
          navigate("/");
        }}
        title="Danke für die Nachricht"
      />
      <ErrorModal
        isOpen={showError}
        massage={result?.data?.message}
        onClick={() => setShowError(false)}
        title="Fehler"
      />

      <div className={classes.section_application_wrapper}>
        <div className={classes.section_application}>
          <div className={classes.container}>
            <div className={classes.title}>{/* <h1>Anwendung</h1> */}</div>
            <form onSubmit={handleSubmit(submitFunc)}>
              <div
                className={`${classes.input_wrapper} ${
                  errors.name ? classes.error : ""
                }`}
              >
                <label htmlFor="name">Vor- und Nachname*</label>
                <div className={classes.input}>
                  <input
                    {...register("name", {
                      required: "Bitte füllen Sie dieses Feld aus.",
                    })}
                  />
                </div>
                {errors.name && (
                  <span className={classes.error}>{errors.name.message}</span>
                )}
              </div>
              <div
                className={`${classes.input_wrapper} ${
                  errors.email ? classes.error : ""
                }`}
              >
                <label htmlFor="email">E-Mail*</label>
                <div className={classes.input}>
                  <input
                    {...register("email", {
                      required: "Bitte füllen Sie dieses Feld aus.",
                      pattern: {
                        value: email_regular,
                        message: "Diese E-Mail ist nicht korrekt.",
                      },
                    })}
                  />
                </div>
                {errors.email && (
                  <span className={classes.error}>{errors.email.message}</span>
                )}
              </div>
              <div
                className={`${classes.input_wrapper} ${
                  errors.mobile ? classes.error : ""
                }`}
              >
                <label htmlFor="phoneNumber">Telefonnummer*</label>
                <div className={classes.input}>
                  <input
                    {...register("mobile", {
                      required: "Bitte füllen Sie dieses Feld aus.",
                    })}
                    type="number"
                  />
                </div>
                {errors.mobile && (
                  <span className={classes.error}>{errors.mobile.message}</span>
                )}
              </div>
              <div
                className={`${classes.input_wrapper} ${
                  errors.job_position ? classes.error : ""
                }`}
              >
                <label htmlFor=".job_position*">Position und Standort</label>
                <div className={classes.input}>
                  <input
                    {...register("job_position", {
                      required: "Bitte füllen Sie dieses Feld aus.",
                    })}
                  />
                </div>
                {errors.job_position && (
                  <span className={classes.error}>
                    {errors.job_position.message}
                  </span>
                )}
              </div>
              <div className={classes.button_layout}>
                <div className={classes.checkbox_layout}>
                  <input
                    type="checkbox"
                    {...register("checkBox", {
                      required: "Bitte akzeptieren Sie unsere Rollen.",
                    })}
                  />
                  <div className={classes.checkbox_wrapper}>
                    <div className={classes.checkbox}></div>
                    <div className={classes.text}>
                      <p>
                        Ich akzeptiere die{" "}
                        <NavLink to="/privacy-policy">
                          Datenschutzbestimmungen
                        </NavLink>
                        .
                      </p>
                    </div>
                  </div>
                  {errors.checkBox && (
                    <span className={classes.error}>
                      {errors.checkBox.message}
                    </span>
                  )}
                </div>
                <ContainedBtnWrapper disabled={isLoading} prevent={true}>
                  {isLoading ? "Warten Sie mal ..." : "Versenden"}
                </ContainedBtnWrapper>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default SectionApplication;
