import classes from "./impressum.module.css";

interface ImpressumType {
  id: number;
  text: string;
}

const initialImpressum: ImpressumType[] = [
  { id: 1, text: "CoPackingSolutions Collaboration GmbH" },
  { id: 2, text: "Landesgericht Korneuburg" },
  { id: 3, text: "Am Schubertbrunnen 21, 2112 Würnitz" },
  { id: 4, text: "Firmenbuch-Nr: FN 545054 a" },
  { id: 5, text: "UID-Nr. ATU76223034" },
  { id: 6, text: "Geschäftsführer: Ali Yilmaz, Christoph Niemann" },
  { id: 7, text: "Telefonnummer 1: 0660/1630972" },
  { id: 8, text: "Telefonnummer 2: 0664/2048911" },
];

const Impressum = () => {
  return (
    <div className={classes.impressum_wrapper}>
      <div className={classes.impressum}>
        <div className={classes.container}>
          <div className={classes.box_wrapper}>
            <div className={classes.box}>
              <div className={classes.title}>
                <h1>Impressum</h1>
              </div>
              <div className={classes.items}>
                {initialImpressum.map((item) => (
                  <div id="impressum" className={classes.item}>
                    <div className={classes.text}>
                      <p>{item.text}</p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Impressum;
