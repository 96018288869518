import classes from "./sectionJobTitle.module.css";

interface ItemsType {
  id: number;
  text: string;
}

interface propsType {
  title: string;
  items: ItemsType[];
}

const SectionJobTitle = ({ title, items }: propsType) => {
  return (
    <div className={classes.sections_job_title_wrapper} id="apply">
      <div className={classes.sections_job_title}>
        <div className={classes.container}>
          <div className={classes.title}>
            <h1>{title}</h1>
          </div>
          <div className={classes.list}>
            <ul>
              {items.map((item) => (
                <li key={item.id}>{item.text}</li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SectionJobTitle;
