import classes from "./markMap.module.css";

interface PropsType {
  left: number;
  top: number;
  location: { text: string; number: number };
  index?:number;
}

const MarkMap = ({ left, top, location, index }: PropsType) => {
  return (
    <div className={`${classes.mark} mark`} style={{ left: `${left}%`, top: `${top}%`, zIndex: index }}>
      <div className={classes.text}>
        <p>
          <span>{location.number}</span>
          {location.text}
        </p>
      </div>
      <div className={classes.icon}>
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M27.4944 11.2666C26.0944 5.10659 20.7211 2.33325 16.0011 2.33325H15.9878C11.2811 2.33325 5.89444 5.09325 4.49444 11.2533C2.93444 18.1333 7.14777 23.9599 10.9611 27.6266C12.3127 28.9348 14.12 29.6664 16.0011 29.6666C17.8144 29.6666 19.6278 28.9866 21.0278 27.6266C24.8411 23.9599 29.0544 18.1466 27.4944 11.2666ZM16.0011 17.9466C15.4496 17.9466 14.9034 17.8379 14.3938 17.6269C13.8843 17.4158 13.4213 17.1064 13.0313 16.7164C12.6412 16.3264 12.3319 15.8634 12.1208 15.3539C11.9097 14.8443 11.8011 14.2981 11.8011 13.7466C11.8011 13.195 11.9097 12.6489 12.1208 12.1393C12.3319 11.6297 12.6412 11.1667 13.0313 10.7767C13.4213 10.3867 13.8843 10.0774 14.3938 9.86629C14.9034 9.65522 15.4496 9.54658 16.0011 9.54658C17.115 9.54658 18.1833 9.98908 18.971 10.7767C19.7586 11.5644 20.2011 12.6327 20.2011 13.7466C20.2011 14.8605 19.7586 15.9288 18.971 16.7164C18.1833 17.5041 17.115 17.9466 16.0011 17.9466Z"
            fill="#666C70"
          />
        </svg>
      </div>
    </div>
  );
};

export default MarkMap;
