import style from "./errorModal.module.css";

interface IProps {
  title: string;
  massage: string;
  onClick: any;
  isOpen: boolean;
}

const ErrorModal = ({ title, massage, onClick, isOpen }: IProps) => {
  return (
    <>
      {isOpen ? (
        <div className={style.main}>
          <div>
            <div>
              <h3>{title}</h3>
              <p>{massage}</p>
              <button onClick={onClick}>Got it</button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};
export default ErrorModal;
