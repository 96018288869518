import classes from "./home.module.css";
import { Header } from "../../components/home/header/header";
import { WhatSection } from "../../components/home/what-section/whatSection";
import { HowSection } from "../../components/home/how-section/howSection";
import { WhySection } from "../../components/home/why-section/whySection";
import { ServiceArea } from "../../components/home/service-area/serviceArea";
import { IndustriesSection } from "../../components/home/industries-section/industriesSection";
import { GreanBoxes } from "../../components/home/green-boxes/greenBoxes";
import { useEffect, useState } from "react";

export const Home: React.FC = () => {
  

  // return loading ? (
  return (
    <div className={classes.home}>
      <Header />
      <GreanBoxes />
      <WhatSection />
      <HowSection />
      <ServiceArea />
      <IndustriesSection />
      <WhySection />
    </div>
  );

  // ) : (
  //   <div>Loading</div>
  // );
};
