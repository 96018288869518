import { Helmet } from "react-helmet";
import { Navbar } from "./../../components/navbar/navbar";
import classes from "./privacyPolicy.module.css";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <title>AGB</title>
      </Helmet>
      <Navbar />
      <div className={classes.privacy_policy_wrapper}>
        <div className={classes.privacy_policy}>
          <div className={classes.container}>
            <h1>AGB CoPackingSolutions Collaboration GmbH</h1>
            <h2>1. Geltungsbereich </h2>
            <p>
              Diese Allgemeinen Geschäftsbedingungen (AGB) regeln die
              Geschäftsbeziehung zwischen uns, der CoPackingSolutions
              Collaboration GmbH, eingetragen zu FN 545054 a des Landesgericht
              Korneuburg mit Sitz in 2112 Würnitz, (nachfolgend auch „wir“,
              „uns“ oder „Auftragnehmer“) und unseren Geschäftskunden
              (nachfolgend „Kunde“ oder „Auftraggeber“ genannt). Als Kunde
              werden ausschließlich Unternehmer im Sinne des § 1
              Unternehmensgesetzbuch (UGB), juristische Personen des
              öffentlichen Rechts oder öffentlich-rechtliche Sondervermögen
              angesehen. Die AGB finden Anwendung auf alle Verträge, die
              zwischen uns und dem Kunden geschlossen werden. Die AGB gelten in
              der zum Zeitpunkt des Vertragsschlusses aktuellen Fassung.
              Abweichende, entgegenstehende oder ergänzende Allgemeine
              Geschäftsbedingungen des Kunden werden nicht Teil des Vertrages,
              es sei denn, ihre Geltung wird unsererseits ausdrücklich und
              schriftlich akzeptiert.
            </p>
            <h2>2. Vertragsabschluss und nachträgliche Auftragsänderungen</h2>
            <ol>
              <li>
                Für das Zustandekommen des detaillierten Auftrages ist unsere
                schriftliche Auftragsbestätigung maßgebend. Die von uns
                übermittelten Angebote sind freibleibend und unverbindlich.
                Preise in Angeboten basieren auf Marktpreisen zum Zeitpunkt der
                Angebotslegung, die sich nach Maßgabe des Punktes 3 erhöhen oder
                verringern können, sofern wir nicht ausdrücklich einen Festpreis
                vereinbaren.
              </li>
              <li>
                Erscheinen bei der Auftragsdurchführung Änderungen oder/und
                Erweiterungen des Auftrags, sind diese vor Ausführung
                schriftlich zwischen den Vertragsparteien zu vereinbaren.
              </li>
              <li>
                In allen Fällen einer durch diese nachträglichen Änderungen
                entstehenden Unzumutbarkeit für den Auftragnehmer, hat dieser
                ein Rücktrittrechts. Der Auftraggeber hat dabei jedoch die
                vereinbarte Vergütung oder mangels Vereinbarung eine angemessene
                Vergütung zu bezahlen.
              </li>
              <li>
                Für diese Berechnung wird die zu diesem Zeitpunkt bereits
                gelieferte Leistung herangezogen, es sei denn, der Auftraggeber
                würde unangemessen benachteiligt.
              </li>
              <li>
                Mündliche Abmachungen, Nebenabreden und Änderungen bedürfen
                zur verbindlichen Gültigkeit die Schriftform.{" "}
              </li>
            </ol>
            <h2>3. Preise</h2>
            <ol>
              <li>
                Wir sind berechtigt, unsere Preise auf Basis der jeweiligen
                Marktpreise anzupassen, falls zwischen Auftragsbestätigung und
                vertraglich vereinbartem Fertigungs- bzw. Liefertermin mehr als
                3 Monate liegen und sich innerhalb dieser Zeit wesentliche
                Kostenfaktoren ändern. Dies umfasst insbesondere
                Kostenerhöhungen aufgrund von Materialpreissteigerungen,
                Lohnkostenänderungen, Engpässen auf dem Beschaffungsmarkt,
                Änderungen in der Rohstoffbeschaffung, oder durch Gesetzgebung
                neu eingeführte oder geänderte Steuern, Abgaben oder sonstige
                hoheitliche Belastungen. Ebenso verpflichten wir uns zur Senkung
                unserer Preise, falls nach Vertragsschluss aufgrund einer
                Änderung der vorangeführten Parameter Kostensenkungen eintreten.
                Diese Bestimmungen gelten nicht, wenn ein Festpreis ausdrücklich
                schriftlich vereinbart ist. Der Auftragnehmer wird den
                Auftraggeber zeitnah über die Notwendigkeit einer Preisanpassung
                informieren und die Gründe hierfür transparent darlegen. Die
                Preisanpassung wird auf der Basis der tatsächlichen Umstände
                vorgenommen und soll angemessen sein.
              </li>
              <li>
                Alle genannten Preise verstehen sich ab Werk ohne Verpackung,
                Fracht- und Versandkosten, Zollkosten, Versicherungen und
                sonstigen Nebenleistungen, außer es wird im Angebot oder in der
                Auftragsbestätigung schriftlich Anderes geregelt.
              </li>
              <li>Alle Preise verstehen sich exkl. Umsatzsteuer.</li>
            </ol>
            <h2>4. Zahlungsbedingungen</h2>
            <ol>
              <li>
                Die Zahlungsfrist beträgt 14 Tage ab Rechnungsdatum ohne
                Abzug. Maßgeblich ist der Zahlungseingang auf unserem Konto.
                Sämtliche Kosten sowie Gebühren gehen zu Lasten des
                Auftraggebers.
              </li>
              <li>
                Im Falle des Zahlungsverzugs sind Verzugszinsen gemäß § 456
                UGB zu zahlen.
              </li>
              <li>
                Wir behalten uns das Recht vor, einen höheren Verzugsschaden
                geltend zu machen, insbesondere die Geltendmachung einer
                Entschädigung für Betreibungskosten gem. § 458 UGB sowie den
                Ersatz der verschuldeten notwendigen Kosten zweckentsprechender
                außergerichtlicher Betreibungs- oder Einbringungsmaßnahmen..
              </li>
              <li>
                Wird uns eine wesentliche Verschlechterung der
                Vermögensverhältnisse des Auftraggebers nach Vertragsabschluss
                bekannt oder entstehen nach Vertragsabschluss sonst begründete
                Zweifel an der Kreditwürdigkeit, welche zu einer Gefährdung der
                Zahlungsverpflichtung des Auftraggebers führt, so behalten wir
                uns das Recht vor, die Vorauszahlung oder sofortige Zahlung
                aller offenen Rechnungen (auch der noch nicht fälligen
                Rechnungen) zu verlangen, noch nicht ausgelieferte Ware
                zurückhalten oder (wenn eine Nachfrist zur Zahlung erfolglos
                verstrichen ist) vom Vertrag fristlos zurücktreten. Diese
                Regelungen gelten auch für den Fall, dass der Auftraggeber trotz
                Mahnung Teile oder die gesamte Schuld nicht bezahlt.
              </li>
            </ol>
            <h2>5. Lieferung und Leistungserbringung</h2>
            <ol>
              <li>
                1. Fertigungs- bzw. Lieferfristen verlängern sich (auch
                innerhalb eines etwaigen Verzugs) angemessen bei Eintritt
                unvorhergesehenen Ereignissen, die wir trotz der nach den
                Umständen des Falles zumutbaren Sorgfalt nicht abwenden konnten,
                z.B. bei Betriebsstörungen, behördlichen Eingriffen,
                Energieversorgungsschwierigkeiten, Verzögerungen in der
                Auslieferung wesentlicher Produktbestandteile. Das gleiche gilt
                im Falle von Streik und Aussperrung.
              </li>
              <li>
                Die Einhaltung von Fristen von Lieferungen und Leistungen
                setzt den rechtzeitigen Eingang sämtlicher vom Auftraggeber
                beizubringender Leistungs- oder Lieferungsbestandteile,
                erforderlicher Genehmigungen und Freigaben sowie die Einhaltung
                der vereinbarten Zahlungsbedingungen und sonstigen
                Verpflichtungen voraus. Werden diese Voraussetzungen nicht
                rechtzeitig erfüllt, so verlängert sich die Frist angemessen.
              </li>
              <li>
                Die Fertigungs- bzw. Lieferfrist ist eingehalten, wenn wir
                bis zum Fristablauf dem Auftraggeber die Abnahmefähigkeit oder
                Versandbereitschaft angezeigt haben.
              </li>
              <li>
                Wird ein Termin oder eine Frist um vier Wochen überschritten,
                so ist der Auftraggeber berechtigt, uns schriftlich unter
                Setzung einer angemessenen Nachfrist zur Leistung aufzufordern.
                Erst nach Verstreichen dieser Nachfrist ist der Auftraggeber zum
                Vertragsrücktritt berechtigt. In die Berechnung dieser Fristen
                fließen Verzögerungen, die durch nicht von uns verursachte bzw.
                nicht uns zuzurechnende Ereignisse eingetreten sind (Abs. 1)
                nicht ein.
              </li>
              <li>
                Teillieferungen und --leistungen sind zulässig, sofern der
                Auftraggeber nichts Gegenteiliges bekannt gibt.
              </li>
              <li>
                Abrufaufträge sind, sofern nichts anders vereinbart ist,
                innerhalb von 3 Monaten nach der Auftragsbestätigung abzurufen,
                ansonsten gehen die entstehenden Kosten sowie angemessene
                Lagergebühren zu Lasten des Auftraggebers.
              </li>
              <li>
                Der Auftraggeber muss den Auftragnehmer rechtzeitig über den
                Abruf informieren.
              </li>
            </ol>
            <h2>Eigentumsvorbehalt und Aufrechnungsverbot</h2>
            <ol>
              <li>
                Alle gelieferten Waren bleiben bis zur vollständigen
                Bezahlung des Kaufpreises und aller damit verbundenen Kosten und
                Auslagen Eigentum des Auftragnehmers.
              </li>
              <li>
                Der Auftraggeber ist verpflichtet, die Ware bis zur
                vollständigen Bezahlung des Kaufpreises sorgfältig zu behandeln.
              </li>
              <li>
                Bei Zugriffen Dritter auf die unter Eigentumsvorbehalt
                stehende Ware, insbesondere bei Pfändungen, ist der Auftraggeber
                verpflichtet, auf das Eigentum des Auftragnehmers hinzuweisen
                und diesen unverzüglich zu benachrichtigen.
              </li>
              <li>
                Bei vertragswidrigem Verhalten des Auftraggebers,
                insbesondere bei Zahlungsverzug, ist der Auftragnehmer
                berechtigt, die Ware zurückzufordern.
              </li>
              <li>
                Der Auftraggeber ist nach schriftlicher Vereinbarung unter
                Nennung des Käufers zur Weiterveräußerung (gleichgültig ob
                unverarbeitet, bearbeitet, verarbeitet oder verbunden) nur im
                ordnungsgemäßen Geschäftsbetrieb berechtigt, die hierdurch
                entstehenden Kaufpreisforderungen tritt der Auftraggeber hiermit
                an den Auftragnehmer zur Besicherung seiner
                (Rest-)Kaufpreisforderung ab. Der Auftraggeber verpflichtet
                sich, gleichzeitig mit dem Abschluss des Vertrages über die
                Weiterveräußerung einen Buchvermerk über die erfolgte Abtretung
                des Weiterveräußerungserlöses an den Auftragnehmer in seinen
                Handelsbüchern vorzunehmen.
              </li>
              <li>
                Veräußert der Auftraggeber den Kaufgegenstand gegen
                Barzahlung an einen Dritten, bevor er den Kaufpreis vollständig
                an den Auftragnehmer gezahlt hat, so nimmt er den zur Zahlung
                der (Rest-)Kaufpreisforderung nötigen Betrag aus dem
                Weiterveräußerungserlös treuhändig für den Auftragnehmer
                entgegen. Er ist verpflichtet diesen Betrag gesondert von seinem
                übrigen Vermögen und als Treuhandgeld des Auftragnehmers zu
                verwahren und als solches zu kennzeichnen. Der Treuhanderlag ist
                unverzüglich und unter Verzicht auf jede Einwendung und Einrede
                auf das Bankkonto des Auftragnehmers einzuzahlen.
              </li>
              <li>
                Wird der Liefergegenstand seitens des Auftraggebers be- oder
                verarbeitet, erstreckt sich unser Eigentumsvorbehalt auch auf
                die neue Sache. Bei der Verarbeitung oder Vermischung mit
                fremden Sachen erwerben wir Miteigentum.
              </li>
              <li>
                Gegenansprüche des Auftraggebers berechtigen nur dann zur
                Aufrechnung oder Zurückbehaltung, wenn diese rechtskräftig
                festgestellt oder von uns anerkannt wurdenf
              </li>
            </ol>
            <h2>7. Gefahrenübergang</h2>
            <ol>
              <li>
                Die Gefahr geht mit Mitteilung der Bereitstellung zur
                Abholung ab Werk auf den Auftraggeber über. Dies gilt auch dann,
                wenn Teillieferungen erfolgen oder der Auftragnehmer noch andere
                Leistungen (z.B. Versand oder Anlieferung) übernommen hat.
              </li>
              <li>
                Treten Verzögerungen ein, die nicht in der Verantwortung des
                Auftragnehmers liegen, zeigt der Auftragnehmer die
                Versandbereitschaft an. Mit dem Zugang dieser Anzeige, geht das
                Risiko auf den Auftraggeber über. Der Auftragnehmer ist nicht
                zum Abschluss von Versicherungen gegen Schäden irgendwelcher Art
                verpflichtet.
              </li>
            </ol>
            <h2>8. Mängelhaftung und Schadenersatz</h2>
            <ol>
              <li>
                Mängelrügen sind, wenn es sich um offensichtliche Mängel
                handelt, umgehend nach Empfang der Liefergegenstandes
                schriftlich geltend zu machen. Nicht offensichtliche Mängel sind
                unverzüglich nach Entdeckung, spätestens innerhalb von zwei
                Monaten ab Lieferung schriftlich zu rügen. Andernfalls gilt die
                Leistung als ordnungsgemäß erbracht.
              </li>
              <li>
                Es steht dem Auftragnehmer zu, durch Nachbesserung oder
                Ersatzlieferung (Nacherfüllung) die gerügten Mängel zu beheben.
              </li>
              <li>
                Werden Teile einer Lieferung gerügt, kann das nicht für die
                gesamte Lieferung gelten, außer, dass dadurch die
                Gesamtlieferung nicht verwendbar ist.
              </li>
              <li>
                Wenn der Auftragnehmer die Nacherfüllung verweigert, oder
                auch diese fehlschlägt, kann der Auftraggeber nur die
                Herabsetzung der Vergütung (Minderung) verlangen. Bei einer nur
                geringfügigen Vertragsverletzung, insbesondere bei nur
                geringfügigen Mängeln, steht dem Auftraggeber jedoch kein
                Rücktrittsrecht zu.
              </li>
              <li>
                Der Auftragnehmer haftet nach den gesetzlichen Bestimmungen
                nur bei Vorsatz oder grober Fahrlässigkeit. Im Fall von leichter
                Fahrlässigkeit haftet er nur für den direkt mit dem
                Schadenseintritt verbundenen Schaden, der typischerweise
                auftritt.
              </li>
              <li>
                Alle Ansprüche aus Gewährleistung und Schadenersatz verjähren
                in einem Jahr nach Gefahrübergang.
              </li>
              <li>
                Weitere Ansprüche des Auftraggebers gegen uns und unsere
                Vertreter, Arbeitnehmer, Angestellten und Erfüllungsgehilfen
                sind ausgeschlossen.
              </li>
            </ol>
            <h2>
              9. Behandlung von Mustern und sämtlichen Unterlagen sowie
              Pflichten des Auftraggebers
            </h2>
            <ol>
              <li>
                Der Auftraggeber haftet dafür, dass durch die Verwendung der
                von ihm vorgelegten oder nach seinen Angaben hergestellten
                Muster usw. Rechte Dritter nicht verletzt werden.
              </li>
              <li>
                Sind dem Auftraggeber Schutzrechte Dritter bekannt, die
                offensichtlich durch die Ausführung des Auftrages verletzt
                würden, wird er uns dies mitteilen. Sämtliche Unterlagen sowie
                von uns hergestellte Muster bleiben unser Eigentum.
              </li>
              <li>
                Der Auftraggeber muss Fertigungsmuster, Produktpässe oder
                Ähnliches prüfen und uns mit Arbeitsanweisung und korrekter
                Rückmeldung rückübergeben. Mit der schriftlichen Rückbestätigung
                des Auftraggebers gilt der laut Fertigungsmuster, Produktpass
                oder Ähnlichem beschriebene Prozess und das beschriebene
                Ergebnis als vom Auftraggeber akzeptiert, wobei vom Auftraggeber
                übersehene Fehler oder falsche Angaben gleichgültig ob bewusst
                oder unbewusst, zu Lasten des Auftraggebers gehen.
              </li>
            </ol>
            <h2>10. Schlussbestimmungen</h2>
            <ol>
              <li>
                Sollte eine Bestimmung dieser AGB oder der ergänzenden
                Vereinbarungen unwirksam sein, werden die übrigen Bedingungen in
                ihrer Gültigkeit nicht berührt. Die Vertragspartner sind
                verpflichtet, die unwirksame Bestimmung durch eine in ihrer
                Wirkung möglichst gleichkommende Regelung zu ersetzen.
              </li>
              <li>
                Es gilt österreichisches Recht unter Ausschluss der
                Kollisions- und Verweisungsnormen die endgültig in ein fremdes
                Recht verweisen sowie unter Ausschluss des UN-Kaufrechts.
              </li>
              <li>
                Ausschließlicher Gerichtsstand für jegliche Streitigkeiten
                aus oder in Zusammenhang mit dem Vertragsverhältnis zwischen uns
                und dem Auftraggeber ist das sachlich zuständige Gericht am Sitz
                des Auftragnehmers. Wir sind jedoch auch berechtigt, das
                sachlich zuständige Gericht, in dessen Sprengel der Auftraggeber
                seinen Sitz oder eine Niederlassung hat, anzurufen.
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
