import style from "./howSection.module.css";
import pic1 from "../../../assets/pics/home/how-section/pic1.svg";
import pic2 from "../../../assets/pics/home/how-section/pic2.svg";
import pic3 from "../../../assets/pics/home/how-section/pic3.svg";
import pic4 from "../../../assets/pics/home/how-section/pic4.svg";
import evenLine from "../../../assets/pics/home/how-section/evenLine.svg";
import oddLine from "../../../assets/pics/home/how-section/oddLine.svg";

export const HowSection: React.FC = () => {
  return (
    <section className={style.howSection}>
      <div className={style.heading}>
        <h2>Unsere Vorgehensweise</h2>
        <p>
          Der CoPacking-Prozess Ihrer Produkte wird mit den neuesten
          Erkenntnissen unserer Experten optimal konzipiert und umgesetzt.
        </p>
      </div>
      <div className={style.bodyContainer}>
        <div className={style.oddItemContainer}>
          <img src={pic1} alt="" />
          <p>
            <h3>
              <p>
                <h3>Ihr Produkt</h3>
                {/* <h4>Lorem impsum</h4> */}
              </p>
              <h3>1</h3>
            </h3>
            <p>
              Um eine hochwertige Präsenz am Markt entwickeln zu können, ist die
              Präsentation Ihrer Produkte wegweisend – dabei unterstützen wir
              Sie gerne.
            </p>
          </p>
        </div>
        <img className={style.lineImg} src={oddLine} alt="" />
        <div className={style.evenItemContainer}>
          <img src={pic2} alt="" />
          <p>
            <h3>
              <p>
                <h3>Das Verpackungsdesign</h3>
                {/* <h4>Lorem impsum</h4> */}
              </p>
              <h3>2</h3>
            </h3>
            <p>
              Gemeinsam mit Ihnen finden wir die attraktivste Verpackungslösung,
              für Ihre Produkte am POS.
            </p>
          </p>
        </div>
        <img className={style.lineImg} src={evenLine} alt="" />
        <div className={style.oddItemContainer}>
          <img src={pic3} alt="" />
          <p>
            <h3>
              <p>
                <h3>Die Konfektionierung</h3>
                {/* <h4>Lorem impsum</h4> */}
              </p>
              <h3>3</h3>
            </h3>
            <p>
              Die Konfektionierung Ihrer Produkte übernimmt unser geschultes
              Personal.
            </p>
          </p>
        </div>
        <img className={style.lineImg} src={oddLine} alt="" />
        <div className={style.evenItemContainer}>
          <img src={pic4} alt="" />
          <p>
            <h3>
              <p>
                <h3>Die Lieferung</h3>
                {/* <h4>Lorem impsum</h4> */}
              </p>
              <h3>4</h3>
            </h3>
            <p>
              Wir bieten eine umfassende Logistiklösung, von der Be-, und
              Entladung des LKWs bis zur Zustellung der Produkte.
            </p>
          </p>
        </div>
      </div>
    </section>
  );
};
