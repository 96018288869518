import classes from "./sectionTwo.module.css";

import SectionTwoImage from "./../../../assets/pics/about-us/all-of-us-trying-image.png";

const SectionTwo = () => {
  return (
    <div className={classes.section_two_wrapper}>
      <div className={classes.section_two}>
        <div className={classes.title}>
          <h1>Wir als Team geben unser Bestes!</h1>
        </div>
        <div className={classes.image}>
          <img src={SectionTwoImage} />
        </div>
        <div className={classes.explain}>
          <p>
            Unsere Kollegen zeichnen sich durch hohe Flexibilität,
            Leistungsbereitschaft und Teamspirit aus.
          </p>
          <p>
            Durch die permanente Weiterentwicklung unserer Mitarbeiter, stellen
            wir ein hohes Maß an Qualität im Umgang mit Ihren Produkten sicher.
          </p>
          <p>
            Jedes unserer Teams besteht aus einem Teamleiter, der mit seinen
            Kollegen die Aufträge nach Ihren Vorgaben ausführt. Darüber hinaus
            verfügen wir über mehrere Taskforces, welche sowohl ungeplante
            Auftragsspitzen als auch spontane Bestellungen durch den Handel, jederzeit ausgleichen können.
          </p>
        </div>
      </div>
    </div>
  );
};

export default SectionTwo;
