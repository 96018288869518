// import Layout from "./Components/Layout"
import { Routes, Route, Navigate } from "react-router-dom";
import { lazy, Suspense } from "react";
import { Home } from "./pages/home/home";
import { ContactUs } from "./pages/contact-us/contactUs";
import { AboutUs } from "./pages/about-us/aboutUs";
import Careers from "./pages/careers/careers";
// import JobTitle from "./pages/careers/job-title/jobTitle";
import PrivacyPolicy from "./pages/privacy-policy/privacyPolicy";
import AdminRouter from "./pages/admin/router";
// import { Home } from "./pages/home/home";
// import { AboutUs } from "./pages/about-us/aboutUs";
// import { ComingSoon } from "./components/coming-soon/comingSoon";
// import ContactUs from "./pages/contact-us/contactUs";

const Router = () => {
  // const Home = lazy(() => import("./pages/home/home"));

  const NotFound = () => {
    return (
      <>
        <div className="mt-[120px] text-center title-1 ">Not found (404)</div>
      </>
    );
  };

  return (
    <Suspense
      fallback={
        <div className="flex items-center justify-center w-full mt-24">
          {/* <LoadingSpinner /> */}
          {/* <LoadingGifSpinner /> */}
        </div>
      }
    >
      <Routes>
        <Route path="*" element={<Navigate to="/" replace />} />
        <Route path="/" element={<Home />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/careers" element={<Careers />} />
        {/* <Route path="/careers/job-title" element={<JobTitle />} /> */}
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/admin/*" element={<AdminRouter />} />
      </Routes>
    </Suspense>
  );
};

export default Router;
