import classes from "./header.module.css";
import { ContainedBtnWrapper } from "../../../ui/contained-btn/containedBtn";
import { OutlinedBtnWrapper } from "../../../ui/outlined-btn/outlinedBtn";
import { Navbar } from "../../navbar/navbar";

//images
import headerPic from "./../../../assets/pics/header/gif.gif";
import loadingHeroImageDefault from "./../../../assets/pics/home/loading-image.png";
import { useState } from "react";

export const Header: React.FC = () => {
  let [loadingHeroImage, setLoadingHeroImage] = useState<boolean>(true);

  const loadedImage = () => {
    setLoadingHeroImage(false);
  };

  return (
    <header className={classes.header}>
      <Navbar />
      <div className={classes.headerContent}>
        <div className={classes.imgContainer}>
            <img src={loadingHeroImageDefault} style={{display: loadingHeroImage ? "block" : "none"}} />
            <img
              src={headerPic}
              style={{display: loadingHeroImage ? "none" : "block"}}
              onLoad={() => loadedImage()}
            />
        </div>
        <div className={classes.titleContainer}>
          <h1>
            Erfahrung, Kompetenz und unsere Leidenschaft, für Ihren Erfolg!
          </h1>
        </div>
        {/* <p>Erfahrung, Kompetenz und unsere Leidenschaft, für Ihren Erfolg!</p> */}
        <div className={classes.btns}>
          <ContainedBtnWrapper linkedTo="/contact-us">
            Kontaktieren Sie uns
          </ContainedBtnWrapper>
          <OutlinedBtnWrapper linkedTo="#what-section">
            Unsere Kompetenzen
          </OutlinedBtnWrapper>
        </div>
      </div>
    </header>
  );
};
