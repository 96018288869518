import { gsap } from "gsap";
import { Bounce } from "gsap/all";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import map from "../../../assets/pics/home/map.svg";
import MarkMap from "../mark-map/markMap";
import classes from "./serviceArea.module.css";
import { useEffect } from "react";

gsap.registerPlugin(ScrollTrigger);

export const ServiceArea: React.FC = () => {
  useEffect(() => {
    let marks = document.querySelectorAll(".mark");
    // let serviceArea = document.querySelector(".serviceArea");
    let Html = document.querySelector("html");
    // let tl;
    let tl1;

    // tl = gsap.timeline().from(serviceArea, {
    //   opacity: 0,
    //   y: 200,
    //   duration: 1,
    // });

    marks.forEach((mark, index) => {
      tl1 = gsap.timeline().from(
        mark,
        {
          scale: 0,
          duration: 1,
          ease: Bounce.easeOut,
        },
        0.5 + index / 6
      );

      ScrollTrigger.create({
        trigger: mark,
        start: "top 90%",
        toggleActions: "play none none none",
        animation: tl1,
        scroller: Html,
      });
    });
    // marks.forEach((mark, index) => {
    //   tl1 = gsap.timeline().to(
    //     mark,
    //     {
    //       scale: 1,
    //       duration: 1,
    //       ease: Bounce.easeOut,
    //     },
    //     1.5 + index / 6
    //   );
    // });
    // ScrollTrigger.create({
    //   trigger: serviceArea,
    //   start: "top 60%",
    //   toggleActions: "play none none none",
    //   animation: tl,
    //   scroller: Html,
    // });
  }, []);

  return (
    <section className={`${classes.serviceArea} serviceArea`}>
      <div className={classes.heading}>
        <h2>Wo sind wir?</h2>
        <p>
          Durch unsere professionelle Arbeitsweise konnten wir mittlerweile
          viele Inhouse-Lösungen bei unseren Kunden etablieren
        </p>
      </div>
      <div className={classes.mapSection}>
        <div className={classes.imgContainer}>
          <MarkMap
            left={44}
            top={42}
            location={{ text: " Seekirchen", number: 2162 }}
          />
          <MarkMap
            left={58}
            top={38}
            location={{ text: " Sattledt", number: 4642 }}
          />
          <MarkMap
            left={60}
            top={29}
            location={{ text: " Ansfelden", number: 4050 }}
          />
          <MarkMap
            left={55}
            top={31}
            location={{ text: " Lambach", number: 4650 }}
          />
          <MarkMap
            left={51}
            top={27}
            location={{ text: " Attnang-Puchheim", number: 4800 }}
          />
          <MarkMap
            left={53.5}
            top={19}
            location={{ text: " Wels", number: 4600 }}
          />
          <MarkMap
            left={49}
            top={18}
            location={{ text: " St. Marienkirchen bei Schärding", number: 4774 }}
          />
          <MarkMap
            left={57.7}
            top={21}
            location={{ text: " Hörsching", number: 4063 }}
          />
          <MarkMap
            left={62}
            top={17}
            location={{ text: " St. Florian", number: 4490 }}
          />
          <MarkMap
            left={86}
            top={17}
            location={{ text: " Wien", number: 1220 }}
          />
          <MarkMap
            left={79}
            top={16}
            location={{ text: " St. Pölten", number: 3105 }}
          />
          <img src={map} />
        </div>
        <h2>Austria</h2>
      </div>
    </section>
  );
};
