import {useState} from "react";
import closeModalIcon from "./../../../../assets/icons/close-modal.svg";
import { PackItemType } from "../../../../types/types";
import classes from "./modal.module.css";

interface ModalProps {
  modalIsOpen: boolean;
  modalData: PackItemType;
  toggleModalFn: () => void;
}

export const Modal: React.FC<ModalProps> = ({
  modalIsOpen,
  modalData,
  toggleModalFn,
}) => {

  const [loadedImage, setLoadedImage] = useState<boolean>(true);

  const loadingImage = () => {
    setLoadedImage(false);
  }

  return (
    <div
      className={`${classes.modalWrapper} ${modalIsOpen ? classes.show : ""}`}
    >
      <div className={classes.modalContainerWrapper}>
        <div className={classes.modalContainer}>
          <div className={classes.modal}>
            <div className="w-full">
              <span
                className="float-right cursor-pointer"
                onClick={() => toggleModalFn()}
              >
                <img src={closeModalIcon} />
              </span>
            </div>
            <div className={classes.heading}>
              <span className={classes.imgContainer}>
                <img src={modalData.modalPic} style={{display: loadedImage? "none" : "block"}} onLoad={loadingImage} />
              </span>
              <div className={classes.headingTxt}>
                <h4 dangerouslySetInnerHTML={{ __html: modalData.title }}></h4>
                <p
                  className="font-semibold"
                  dangerouslySetInnerHTML={{ __html: modalData.text }}
                ></p>
              </div>
            </div>
            <p dangerouslySetInnerHTML={{ __html: modalData.paragragh }}></p>
          </div>
        </div>
      </div>
      <div className={classes.backdrop} onClick={() => toggleModalFn()}></div>
    </div>
  );
};
