const BASE_URL = "https://copackingsolutions.com/api";

const api = {
  user: {
    contact_us: `${BASE_URL}/user/contact_us`,
    job_request: `${BASE_URL}/user/job_request`,
  },
};

export default api;
