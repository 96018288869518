import classes from "./header.module.css";

import HeaderImage from "./../../../assets/pics/join-us/header-image.svg";
import { OutlinedBtnWrapper } from "../../../ui/outlined-btn/outlinedBtn";
import { ContainedBtnWrapper } from "../../../ui/contained-btn/containedBtn";

const Header = () => {
  return (
    <div className={classes.join_us_header_wrapper}>
      <div className={classes.title}>
        <div className={classes.text}>
          <h1>Bewirb</h1>
        </div>
        <div className={classes.image}>
          <img src={HeaderImage} />
        </div>
        <div className={classes.text}>
          <h1>Dich jetzt!</h1>
        </div>
      </div>
      <div className={classes.explain_wrapper}>
        <div className={classes.explain_title}>
          <h1>Komm ins Team der Möglichmacher – Gestalter - Umsetzer!</h1>
        </div>
        <div className={classes.explain}>
          <p>
            Wir sind ein junges, aufstrebendes Unternehmen mit Standorten
            verteilt in ganz Österreich. Wir sind ein leistungsstarker Partner
            im Bereich Logistik und Co-Packing.
          </p>
          <p>Um unseren Standort in Amstetten zu unterstützen, suchen wir:</p>
          <span>Mitarbeiter in der Verpackung (m/w/x)</span>
          <p>in Teil-/ und Vollzeit.</p>
        </div>
        <div className={classes.buttons}>
          <OutlinedBtnWrapper linkedTo="https://copackingsolutions.com/api/Adata/inserat_verpacker.pdf">
          Verpacker/Amstetten
          </OutlinedBtnWrapper>
          <ContainedBtnWrapper linkedTo="#apply">
          Bewerbung
          </ContainedBtnWrapper>
        </div>
      </div>
    </div>
  );
};

export default Header;
